import React from 'react';
import {connect} from "react-redux";
import {VESSEL_DEVICE_TYPE_NAME} from "../../util/varibles/constants";
import WSService from "../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import {IVessel} from "../../util/varibles/interface";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {VIEW_MODE} from "../../util/varibles/permission";
import {dashboardActions} from "./reducer";
import {getSetting} from "../../util/varibles/defaultSetting";
import {AppState} from "../../util/store/store";
import {locationActions} from "../../util/store/location/reducer";
import {regionActions} from "../../util/store/region/reducer";
import Map from "./Map";

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);

    return {
        barentsSwatch: state.login.barentsSwatch,
        viewMode,
        permission: state.login.user.permission,
    }
};

interface IProps {
    viewMode: VIEW_MODE
    barentsSwatch: any
    permission: any

    getVessels(callback?: any): void

    getData(): void

    updatePosition(data: any): void

    getSites(): void

    getRegions(): void

    getFactories(): void

    getLocations(): void

    updateOperations(list: any): void
}

class DashboardPage extends React.Component<IProps> {
    socket: WSService | undefined;
    gps: any = {};
    timeout: any;
    intervalBarentsSwatch: any;
    groupIds = [];
    isOpen = true;
    loadData = false;

    constructor(props: any) {
        super(props);
        const {permission} = this.props;

        this.props.getVessels((vessels: IVessel[]) => this.registerWebSocket(vessels));
        this.props.getSites();
        this.props.getRegions();
        this.props.getFactories();
        this.props.getLocations();
        if (permission[VIEW_MODE.PLANNER]) {
            this.loadData = true;
            this.props.getData();
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if (nextProps.permission[VIEW_MODE.PLANNER] && !this.loadData) {
            this.loadData = true;
            this.props.getData();
        }
        return true
    }

    componentWillUnmount(): void {
        this.isOpen = false;

        if (this.socket) {
            this.socket.removeListener(SOCKET_LEV.PAGE);
            this.socket.unregisterGroups(this.groupIds);
        }

        if (this.timeout)
            clearTimeout(this.timeout);

        if (this.intervalBarentsSwatch)
            clearInterval(this.intervalBarentsSwatch);
    }

    processMessage = (response: any) => {
        if (!response.function) return;

        switch (response.function) {
            case SOCKET_FUNC.VESSEL_GPS: {
                if (this.timeout)
                    clearTimeout(this.timeout);

                const {vessel_id, name, value, time} = response;
                const {time: oldTime = 0} = this.gps;
                const [gpsTime] = time.split('.');
                const updateTime = gpsTime[gpsTime.length - 1] === 'Z' ? new Date(gpsTime.replace(' ', 'T')).getTime() :
                    new Date(gpsTime.replace(' ', 'T') + 'Z').getTime();
                const newTime = Math.max(updateTime, oldTime);
                this.gps = {
                    ...this.gps,
                    [vessel_id]: {...this.gps[vessel_id] || {}, [name]: value, time: newTime}
                };
                this.timeout = setTimeout(() => {
                    this.props.updatePosition(this.gps);
                    this.gps = {};
                }, 1000)
                break;
            }
            case SOCKET_FUNC.OPERATION_STATE_CHANGE:
            case SOCKET_FUNC.OPERATION_STATUS_CHANGE: {
                const list = response.message;
                this.props.updateOperations(list);
                break;
            }
            default:
                return;
        }
    }

    registerWebSocket = (vessels: any) => {
        if (vessels.length > 0) {
            this.groupIds = vessels.reduce((list: any, item: any) => {
                return [...list, item.id, item.id + "-" + VESSEL_DEVICE_TYPE_NAME.LOCATION]
            }, [])
            this.socket = new WSService({
                groups: this.groupIds, listener: {
                    lev: SOCKET_LEV.PAGE,
                    func: (value: any) => this.processMessage(value)
                }
            });
        }
    }

    render() {
        return <div className="m-header d-flex bgPage">
            <Map/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getVessels: dashboardActions.getVesselsRequest,
    getData: dashboardActions.getDataRequest,
    updatePosition: dashboardActions.updatePositionVessel,
    getSites: dashboardActions.getSitesRequest,
    getRegions: regionActions.getRegions,
    getFactories: dashboardActions.getFactoriesRequest,
    getLocations: locationActions.getPois,
    updateOperations: dashboardActions.updateOperations,
})(DashboardPage);
