import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDataRequest: (state) => {
            state.loadingOp = true
        },
        getDataSuccess: (state, action) => {
            state.operations = action.payload;
            state.loadingOp = false
        },
        getDataFailure: (state) => {
            state.loadingOp = false
        },
        getSitesRequest: (state) => {
        },
        getSitesSuccess: (state, action) => {
            state.sites = action.payload
        },
        getSitesFailure: (state) => {
        },
        getFactoriesRequest: (state) => {
        },
        getFactoriesSuccess: (state, action) => {
            state.factories = action.payload;
        },
        getFactoriesFailure: (state) => {
        },
        updatePositionVessel: (state, action) => {
            const {vessels = [], target = {}} = state;
            const gpsOfVessels = action.payload;

            state.vessels = vessels.map((vessel: any) => {
                if (gpsOfVessels[vessel.id]) {
                    return {...vessel, gps: {...vessel.gps, ...gpsOfVessels[vessel.id]}}
                }
                return vessel;
            })

            const {id = null, gps} = target.vessel || {};
            if (id && gpsOfVessels[id])
                state.target = {...target, vessel: {...target.vessel, gps: {...gps || {}, ...gpsOfVessels[id]}}};
        },
        getVesselsRequest: (state, action) => {
            state.loadingVessels = true
        },
        getVesselsSuccess: (state, action) => {
            state.vessels = action.payload
            state.loadingVessels = false
        },
        getVesselsFailure: (state) => {
            state.loadingVessels = false
        },
        togglePopupInfo: (state, action) => {
            const {isShow, values} = action.payload.params;
            return {...state, popupInfo: {isShow, values}}
        },
        updateOperations: (state, action) => {
            const list = action.payload;
            const {operations} = state;
            state.operations = operations.map((item: any) => {
                const {id} = item.operation;
                const target = list.find((sub: any) => sub.operation.id === id);
                if (target) {
                    const {status, current_process} = target.operation;
                    return {
                        ...item,
                        current_process,
                        operation: {...item.operation, status},
                        activity_log: target.activity_log
                    }
                }
                return item
            })
        },
        getWeatherRequest: (state, action) => {
            state.weather = {}
            state.loadingWeather = true
        },
        getWeatherSuccess: (state, action) => {
            state.weather = action.payload
            state.loadingWeather = false
        },
        getWeatherFailure: (state) => {
            state.loadingWeather = false
        },
        showInfo: (state, action) => {
            const target = action.payload;
            const {targetType = null} = target;

            if (targetType === null) {
                state.tracking = false
            }

            state.target = target;
        },
        updateLoadingProdAreas:(state,action)=> {
            state.loadingProdAreas = action.payload
        },
        updateProductionAreas: (state, action) => {
            state.productionAreas = action.payload
        },
        updateTracking: (state, action) => {
            state.tracking = action.payload
        }
    },
})

export const dashboardActions = dashboardSlice.actions

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
